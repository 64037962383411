import React from 'react'
import { images } from 'config/images'

export const AppDevSerProps = {
  ppcbannerProps: {
    heading: (
      <>
        Application development services to{' '}
        <span className="highlight-head">modernize your business </span>
      </>
    ),
    para: (
      <>
        By blending product design, engineering, analytics, and automation, we
        build the <span className="color-through">modern apps</span> of
        tomorrow, today
      </>
    ),
    formCTA: 'Get quote in 24 hours',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Building apps your
        <br /> <span className="highlight-head">users love</span> starts here
      </>
    ),
    buildAppPara: (
      <>
        Building the future in a shifting landscape requires the agility of
        action and attitude. Our extended teams provide{' '}
        <span className="color-through">scale, experience, acceleration,</span>{' '}
        and a whole lot of muscle - to help you rapidly create modern
        applications that will disrupt the world.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Complete range of{' '}
        <span className="highlight-head">app development</span> services
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'UX design and Prototyping',
        serviceParagraph: (
          <>
            We blend intuitive and efficient design with behavioral patterns to
            create a <strong>delightful user experience.</strong> Powerful UI
            and UX enable higher user adoption, easier onboarding, and
            ultimately more sales.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'UX',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: <>Web and Mobile Apps Development</>,
        serviceParagraph: (
          <>
            We build high performance and <strong>scalable applications</strong>{' '}
            using the latest programming languages and frameworks. These
            applications have led to increased productivity, and process
            efficiency for our clients.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Web',
        serviceClass: 'pale',
      },

      {
        id: '3',
        serviceHeading: <>VR and AR Apps Development </>,
        serviceParagraph: (
          <>
            Leverage the power of extended realities to{' '}
            <strong>deliver innovative applications</strong> and experiences to
            your users that were simply not possible before.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Cross-Platform and Hybrid Apps',
        serviceParagraph: (
          <>
            Our <strong>cross-platform and hybrid</strong> app development
            offering helps business' target multiple platforms with the same
            code base and save costs.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Integration',
        serviceClass: 'pale-lavender',
      },
      {
        id: '5',
        serviceHeading: 'API Development and Integration',
        serviceParagraph: (
          <>
            We create <strong>APIs for mobile</strong>, cloud apps, web sockets,
            browsers and databases to facilitate access to application data,
            features and business logic.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'API',
        serviceClass: 'pale-grey',
      },

      {
        id: '6',
        serviceHeading: 'Quality Assurance and Software Testing',
        serviceParagraph: (
          <>
            We don’t just test functionality or match developed features with
            your requirements, we <strong>test to ensure</strong> that users can
            use the application as intended.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'Quality',
        serviceClass: 'ice',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Rapid application delivery using Agile',
        workingBenefitsContent: (
          <>
            Customers today need improved speed, quality, and reliability of
            applications. Our <strong>Agile practice</strong> helps you build
            multi-platform apps with faster iterations, delivery times, and with
            higher quality.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Microservices architecture for independent resilience',
        workingBenefitsContent: (
          <>
            We work with <strong>Microservices architecture</strong> where a
            monolithic app is split into independent units, allowing for
            continuous deployment, increased uptime, and partial updates.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Architecture that perfectly responds to your goals',
        workingBenefitsContent: (
          <>
            Reactive architecture for creating{' '}
            <strong>resilient and flexible applications</strong> that elegantly
            handle ‘live’ data, high load, and bring impeccable UX with its high
            responsiveness.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">Case studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/how-to-build-messaging-app-whatsapp-telegram-slack/',
        featurecardTitle:
          'How to Build a Messaging App like Whatsapp, Telegram, Slack',
      },
      {
        blogLink: 'https://www.simform.com/how-make-app-like-airbnb/',
        featurecardTitle:
          'How to Make an app like Airbnb : Develop your own Vacation Rental App',
      },
      {
        blogLink: 'https://www.simform.com/react-vs-vue/',
        featurecardTitle:
          'React vs Vue – The CTOs guide to Choosing the Right Framework',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactHs,
    personImg2x: images.contactHs2x,
    personName: 'Hardik Shah',
    personDesignation:
      'Creating a tech product roadmap and building scalable apps for your organization.',
    personEmail: 'hardik@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
